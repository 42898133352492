import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

import { 
  get_list_of_classes,
  get_entities_of_class,
  get_property_info,
  base_doc_uri,
  get_entities_of_all_classes,
  convert_list_of_entities,
  get_narrower,
} from '@/api/ontologias'


export const store = new Vuex.Store({
    state: {
        count: -1,
        class_list: [],
        entities_by_class_with_label: {},
        property_labels: {},
        property_ranges: {},
        properties_by_domain: {},
        properties_by_range: {},
        dataProperties_by_domain: {},
        catalogProperties_by_domain: {},
        existing_entity_mentions: [],
        existing_relation_mentions: [],
        doc_id: "",
        doc_uri: "",
        catalogues: {},
        propertyrangetypes: {},
        entity_labels: {},
        class_labels: {},
        all_entity_info: {},
        narrower_to: {}


    },
    getters: {
        items: state => {
            return state.class_list;
        }
    },
    mutations: {
        increment(state) {
            state.count++
        },
        SET_doc_id(state, doid) {
            state.doc_id = doid
            state.doc_uri = "<" + base_doc_uri + doid + ">"
        },

        SET_classes(state, clist) {
            state.class_list = clist
            for (var cln in clist) {
                var cl = clist[cln]
                state.class_labels[cl["id"]] = cl["name"]
            }
        },
        SET_entities(state, ents_cla) {
            var entities = ents_cla[0]
            var cls = ents_cla[1]
            state.entities_by_class_with_label[cls] = entities
            //console.log("setting entities for "+cls)
            for (var entn in entities) {
                var ent = entities[entn]
                state.entity_labels[ent["id"]] = ent["name"]
            }
        },
        SET_property_info(state, entity_info) {
            console.log("--!!SETTING PROPERTY INFO!!--")
            state.property_labels = entity_info["labels"]
            state.properties_by_domain = entity_info["with_domain"]
            state.properties_by_range = entity_info["with_range"]
            state.dataProperties_by_domain = entity_info["dataprops_with_domains"]
            state.catalogProperties_by_domain = entity_info["catalogprops_with_domains"]
            state.propertyrangetypes = entity_info["rangetypes"]
            state.property_ranges = entity_info["property_ranges"]

        },
        SET_catalogues(state, catalogues) {
            state.catalogues = catalogues
            for (var catan in catalogues) {
                var catalogue = catalogues[catan]
                for (var entn in catalogue) {
                    var ent = catalogue[entn]
                    state.entity_labels[ent["uri"]] = ent["label"]

                }
            }

        },
        SET_narrower_to(state, {broader, narrowers}){
            console.log("....Narrowers for "+broader)
            console.log(narrowers)
            console.log("...!")
            state.catalogues[broader] = narrowers[broader]
            for (var entn in narrowers[broader]){
                var ent = narrowers[broader][entn]
                state.entity_labels[ent["uri"]] = ent["label"]
            }
        },
        SET_existing_entity_mentions(state, mentions) {
            state.existing_entity_mentions = mentions
        },
        /*
          entity_mentions have the form:
              {
                  "mention_queue_id": string(uuid4)
                  "docid": "string(uri)",
                  "entity_class": "string(uri)",
                  "entity_properties": [
                      {
                      "ent_property": "string(uri)",
                      "ent_value": "string(uri)",
                      "meta_properties": [
                          {
                          "ent_property": "string(uri)",
                          "ent_value": "string(uri/string)"
                          }
                      ]
                      }
                  ],
                  "mention_polygon": {
                      "topleft_x": "integer",
                      "topleft_y": "integer",
                      "bottomright_x": "integer",
                      "bottomright_y": "integer"
                  }
              }
        */
        SET_add_entity_mention(state, entity_mention) {
            state.existing_entity_mentions = [...state.existing_entity_mentions,
                entity_mention]
        },
        RESET_entity_mentions(state) {
            state.existing_entity_mentions = []
        },
        RESET_relation_mentions(state) {
            state.existing_relation_mentions = []
        },
        SET_add_relation_mention(state, relation_mention) {
            state.existing_relation_mentions = [...state.existing_relation_mentions,
                relation_mention]
        },
        DEL_relation_mention(state, id) {
            state.existing_relation_mentions = state.existing_relation_mentions.filter(relm => relm.mention_id !== id)

        },
        SET_entity_id(state, entidentinfo) {
            var entid = entidentinfo[0]
            var entinfo = entidentinfo[1]
            state.all_entity_info[entid] = entinfo
        }


    },
    actions: {
        loadClasses({ commit }) {
            let pr = get_list_of_classes()
            pr.then(clist => commit('SET_classes', clist["classes_data"]))
            pr.then(clist => commit('SET_catalogues', clist["catalogue_data"]))
            pr.then(clinfo => commit("SET_property_info", clinfo["property_data"]))
            return pr
        },    
        async get_narrowers({ commit }, broader) {
            await get_narrower(broader).then(narrowers => {console.log("------")
                                                 console.log(narrowers)
                                                 commit('SET_narrower_to', {broader, narrowers})                                            
                                                 console.log("----- Populated narrowers for "+broader)})
            
        },    
        /*
        async populateClasses ({commit}){
          console.log("Populating ------------------")
          var sorted_for_fetching = await sorturits(this.state.class_list, "id")
          for (var cl in sorted_for_fetching ){        
            var classid = sorted_for_fetching [cl]["id"]            
            await get_entities_of_class(classid)
              .then(res => commit('SET_entities', [res, classid]))
          }      
        },*/
        async populateClasses({ commit }) {
            console.log("Populating ------------------")
            await get_entities_of_all_classes()
                .then(res => {
                    for (var clnum in res) {
                        var cldata = res[clnum]
                        var classid = cldata["classid"]
                        var classcontents = cldata["entities"]
                        var classcontentparsed = convert_list_of_entities(classcontents)
                        commit('SET_entities', [classcontentparsed, classid])
                    }
                    console.log("------- -Populated " + res.length + " classes")
                })
        },
        async populateSpecificClass({ commit }, classid) {
            await get_entities_of_class(classid)
                .then(res => commit('SET_entities', [res, classid]))

        },
        async populatePropertyInfo({ commit }) {
            console.log("Getting property info")
            return get_property_info()
                .then(clinfo => commit("SET_property_info", clinfo))
        },
        add_entity_mention({ commit }, entmention) {
            commit('SET_add_entity_mention', entmention)
        },
        add_relation_mention({ commit }, relmention) {
            commit('SET_add_relation_mention', relmention)
        },
        rem_relation_mention({ commit }, id) {
            commit('DEL_relation_mention', id)
        },
        clear_entity_mentions({ commit }) {
            commit('RESET_entity_mentions')
        },
        clear_relation_mentions({ commit }) {
            commit('RESET_relation_mentions')
        },
        set_docid({ commit }, docid) {
            commit('SET_doc_id', docid)
        },
        set_entity_mentions({ commit }, entmentions) {
            commit('SET_existing_entity_mentions', entmentions)
        },
        set_entity_info({ commit }, entidentinfo) {
            commit('SET_entity_id', entidentinfo)
        }

    },

})


  // -------------- END STORE -------------------------------
