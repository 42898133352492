import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'


// ---------------------------------- U  R  L  S ---------------------------------
const api_base = process.env.VUE_APP_APIONTO
const doc_api_base = process.env.VUE_APP_APIDOC
export let api_doc_get = doc_api_base + "document?docid="
// -------------------------------------------------------------------------------

console.log("\n\n------- INTIALIZED API CLIENT ------------\n")
console.log(api_base)
console.log(doc_api_base)
console.log("\n-----------------------------------------")
console.log("-----------------------------------------\n\n")


// let api_get_classes = "classes"
let api_get_entityinfo = "entities/by_id?entity_id=" // (Otto Done)
let api_get_entities_of_class = "entities/by_class_with_labels?class_id="  // (Otto Done)
let api_get_entities_all_classes = "entities/all_classes"  // (Otto Done)
let api_property_info = "properties"  // Move this to static
let api_mention_existing_entity = "annotation/existing_entity" // Otto done
let api_get_severalentities_info = "entities/by_ids"
let api_mention_new_entity = "annotation/new_entity"
let api_static_schemas = "static_schemas"  // (Otto Knows where to do)
let api_mention_relation = "annotation/new_relation"
let api_mentions_by_doc = "entities/by_document?document_id="
let api_patch_entity = "entities/by_id"
let api_get_narrower = "catalogs?id_catalogo="
const api_del_annotation = 'annotation/by_id?annotation_uri=' // /annotation/by_id?annotation_uri=<https://entidades.pruebascnb.org/Anotaciones/A_a2617250-cff8-494e-8eb3-8cd4f5c7f309>
// <https://entidades.pruebascnb.org/autores/ElisaFerracane_b3e59d>
// <https://test-documentos.pruebascnb.org/ANGELUS/LRVPLT/CP3S41>


export let titleuri = "<https://ontologias.pruebascnb.org/v1.0/tieneTitulo>"
export let numberinguri = "<https://ontologias.pruebascnb.org/v1.0/tieneFoliacion>"
export let onto_base_class = "<https://ontologias.pruebascnb.org/v1.0/ThingsAngelus>"
export let base_doc_uri = "https://documentos.pruebascnb.org/ANGELUS/"
export let default_doc_id = "S7IUNP/WUNITN"
export let ignore_doc_ids_with = "CK7UDC"

export const min_prop = '<https://ontologias.pruebascnb.org/v1.0/tieneNombre>'

export var orderdict = {
    '<https://ontologias.pruebascnb.org/v1.0/Persona>': -10000,
    '<https://ontologias.pruebascnb.org/v1.0/Organización>': -10000,
    '<https://ontologias.pruebascnb.org/v1.0/Organizacion>': -10000,
    '<https://ontologias.pruebascnb.org/v1.0/Evento>': -10000,
    '<https://ontologias.pruebascnb.org/v1.0/Lugar>': -10000,

    '<https://ontologias.pruebascnb.org/v1.0/tieneNombre>': -1250,
    '<https://ontologias.pruebascnb.org/v1.0/tieneNombresPropios>': -1000,
    '<https://ontologias.pruebascnb.org/v1.0/tieneApellidoPaterno>': -990,
    '<https://ontologias.pruebascnb.org/v1.0/tieneApellidoMaterno>': -980,
    '<https://ontologias.pruebascnb.org/v1.0/tieneAlias>': -970,
    '<https://ontologias.pruebascnb.org/v1.0/tieneNombreAlternativo>': -960,

}

export var identity_function = function (a) { return a }
export var dates_input = function (a, b, c, d) {
    return {
        'start_date_lower_bound': a,
        'start_date_upper_bound': b,
        'end_date_lower_bound': c,
        'end_date_upper_bound': d
    }
}

export var provenancedict = {
    '<https://ontologias.pruebascnb.org/v1.0/tieneObservaciones>': {
        'jsonfield': 'observaciones',
        'input_type': ['literal'],
        'jsonsubfields': [null],
        'property_labels': ["Observaciones"],
        'range': "string"
    },
    '<https://ontologias.pruebascnb.org/v1.0/tieneConfianza>': {
        'jsonfield': 'confianza',
        'input_type': ['catalogue'],
        'jsonsubfields': [null],
        'property_labels': ["Nivel de Confianza"],
        'range': "<https://vocabularios.pruebascnb.org/v1.0/Confianza>"
    },
    // '<https://ontologias.pruebascnb.org/v1.0/tieneMetodoInferencia>': {        
    //     'jsonfield' : 'metodo_inferencia',
    //     'input_type' : ['catalogue'],
    //     'jsonsubfields' : [null],
    //     'property_labels':["Método de Inferencia"],
    //     'range': '<https://vocabularios.pruebascnb.org/v1.0/MetodoInferencia>'  },    
    '<https://ontologias.pruebascnb.org/v1.0/tienePeriodoDeValidez>': {
        'jsonfield': 'validity_period',
        'input_type': ['datetime', 'datetime', 'datetime', 'datetime'],
        'property_labels': ["Inicio cota inferior",
            "Inicio cota superior  ",
            "Fin cota inferior",
            "Fin cota superior  ",
        ],
        'jsonsubfields': ['start_date_lower_bound',
            'start_date_upper_bound',
            'end_date_lower_bound',
            'end_date_upper_bound'],
        'range': "date"
    }

}

// --------------------------------------------------------
//  U T I L I T I E S   -----------------------------------
// --------------------------------------------------------

export async function sleep(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
}


function hashCode(somestr) {
    var hash = 0, i;
    for (i = 0; i < somestr.length; i++) {
        hash += somestr.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
    }
    return hash;

}

export function sortingValueForProperty(datadict, sortkey) {

    var dpr = datadict[sortkey]
    if (dpr in orderdict) {
        return orderdict[dpr]
    }
    return hashCode(dpr)
}

export async function sorturits(urilist, sortkey) {
    var newlist = urilist.slice()
    newlist.sort((a, b) => sortingValueForProperty(a, sortkey) - sortingValueForProperty(b, sortkey))
    return newlist
}

// --------------------------------------------------------
//   C O N V E R T E R S ----------------------------------
// --------------------------------------------------------

function convert_catalogs(response) {
    var res = {}
    for (var catid of Object.keys(response)) {
        var itlist = []
        var lab = response[catid]["labels"][0]
        for (var iteuri of Object.keys(response[catid]["items"])) {
            itlist = [...itlist, {
                "uri": iteuri,
                "label": response[catid]["items"][iteuri]["prefLabel"][0]+" ("+lab+")"
            }]
        }
        res[catid] = itlist
    }
    return res
}

function convert_list_of_classes(response) {
    var resu_data = []
    for (var classid of Object.keys(response)) {
        var new_class = { id: classid, name: response[classid]["label"][0] }
        resu_data = [...resu_data, new_class]
    }
    resu_data.sort((a, b) => a.name.localeCompare(b.name))
    return resu_data
}

export function convert_list_of_entities(response) {
    var resu_data = []

    for (var entn in response) {
        var thisent = response[entn]
        var ln = ""
        if (thisent["longname"] !== null && thisent["longname"].length > 0) {
            ln = "   (" + thisent["longname"] + ")"
        }
        var new_ent = {
            id: thisent["entity"],
            name: thisent["labels"][0]["label_value"],
            longname: thisent["labels"][0]["label_value"] + ln
        }
        resu_data = [...resu_data, new_ent]
    }
    return resu_data
}

function convert_info_of_properties(raw_property_info) {
    var prop_data = {
        labels: {},
        rangetypes: {},
        property_ranges: {}
    }
    var domains = {}
    var domains_data = {}
    var cata_data = {}
    var ranges = {}

    var prop_domains = []
    var prop_ranges = []
    var prop_label = ""

    var inthis = []
    for (var propid of Object.keys(raw_property_info)) {
        var this_prop_data = raw_property_info[propid]
        var this_range_type = this_prop_data["rangetype"]
        prop_label = this_prop_data["label"][0]
        prop_domains = this_prop_data["<http://www.w3.org/2000/01/rdf-schema#domain>"]
        prop_ranges = this_prop_data["<http://www.w3.org/2000/01/rdf-schema#range>"]

        prop_data["labels"][propid] = prop_label
        prop_data["rangetypes"][propid] = this_range_type
        prop_data["property_ranges"][propid] = prop_ranges[0]
        switch (this_range_type) {
            // if (prop_ranges[0].startsWith("<http://www.w3.org/2001/XMLSchema#"))
            case "literal":
                {
                    for (const dom of prop_domains) {
                        if (dom in domains_data) { inthis = domains_data[dom] } else { inthis = [] }
                        inthis = [...inthis, propid]
                        domains_data[dom] = inthis
                    }
                    //console.log("Literal property "+propid+" : "+this_range_type)
                    break;
                }
            case "object":
                {
                    for (const dom of prop_domains) {
                        if (dom in domains) { inthis = domains[dom] } else { inthis = [] }
                        inthis = [...inthis, propid]
                        domains[dom] = inthis
                    }
                    for (const ran of prop_ranges) {
                        if (ran in ranges) { inthis = ranges[ran] } else { inthis = [] }
                        inthis = [...inthis, propid]
                        ranges[ran] = inthis
                    }
                    //console.log("Object property "+propid+" : "+this_range_type)
                    break;
                }
            case "catalogue": {
                for (const dom of prop_domains) {
                    if (dom in cata_data) { inthis = cata_data[dom] } else { inthis = [] }
                    inthis = [...inthis, propid]
                    cata_data[dom] = inthis
                }
                //console.log("Catalog property "+propid+" : "+this_range_type)
                break;
            }
            default: {
                console.log("Weird property returned " + this_prop_data)
            }

        }

    }
    prop_data["with_domain"] = domains
    prop_data["dataprops_with_domains"] = domains_data
    prop_data["catalogprops_with_domains"] = cata_data
    prop_data["with_range"] = ranges

    return prop_data
}


function convert_info_of_entities(dataprops, objectprops, inverseprops) {
    var ops = []
    var dps = []
    for (var opsn in objectprops) {
        var oopsn = objectprops[opsn]
        if (oopsn == null ||
            oopsn["predicate"] == null ||
            oopsn["object_labels"] == null ||
            oopsn["object_labels"].length < 1) {
            continue
        }
        var objprop = {
            "type": "direct",
            "predicate": oopsn["predicate"],
            "object": oopsn["object_labels"][0]["label_value"],
            annotation_id: oopsn.provenance?.anotacion || null,
            valid_annotation: !!oopsn.provenance?.is_valid
        }
        ops = [...ops, objprop]
    }


    for (opsn in inverseprops) {
        var iopsn = inverseprops[opsn]
        if (iopsn == null ||
            iopsn["predicate"] == null ||
            iopsn["object_labels"] == null ||
            iopsn["object_labels"].length < 1) {
            continue
        }
        var invprop = {
            "type": "inverse",
            "predicate": iopsn["predicate"],
            "object": iopsn["object_labels"][0]["label_value"]
        }
        ops = [...ops, invprop]
    }


    for (opsn in dataprops) {
        var dopsn = dataprops[opsn]

        if (dopsn == null ||
            dopsn["predicate"] == null ||
            dopsn["literal"] == null) {
            continue
        }
        console.log('//////', dopsn)
        var dprop = {
            "predicate": dopsn["predicate"],
            "object": dopsn["literal"],
            annotation_id: dopsn.provenance?.reification_uri || null,
            valid_annotation: !!dopsn.provenance?.is_valid
        }
        dps = [...dps, dprop]
    }

    dps.sort((a, b) => sortingValueForProperty(a, "predicate")
        - sortingValueForProperty(b, "predicate"))

    return {
        'object_properties': ops,
        'data_properties': dps
    }
}

function convertmentions(mentions_reponse) {
    console.log('mentions response', mentions_reponse)
    var entity_mentions = []
    var relation_mentions = []
    for (var emn in mentions_reponse["mentioned_entities"]) {
        var em = mentions_reponse["mentioned_entities"][emn]
        var emours = {}
        emours["class_id"] = em["entity_class"]
        emours["class_label"] = ""
        emours["entity_id"] = em["entity"]
        if (em["entity_labels"] != null && em["entity_labels"].length > 0) {
            emours["entity_label"] = em["entity_labels"][0]["label_value"]
        }
        else {
            emours["entity_label"] = em["entity"]
        }
        emours["mention_id"] = uuidv4()
        emours["new"] = false
        emours["already_added"] = true
        emours["finished"] = true
        emours["pre_existing"] = true
        emours["had_properties_altered"] = false
        // annotation
        emours['annotation_id'] = em.locations[0].anotacion || null
        emours['valid_annotation'] = em.locations[0].is_valid
        entity_mentions = [...entity_mentions, emours]
    }

    for (var rmn in mentions_reponse["mentioned_relations"]) {
        var rm = mentions_reponse["mentioned_relations"][rmn]
        var rmours = {}
        rmours["mention_id"] = uuidv4()
        rmours["subject"] = rm["subject"]
        rmours["object"] = rm["object"]
        rmours["predicate"] = rm["predicate"]
        rmours["subject_label"] = null
        rmours["object_label"] = null
        rmours["predicate_label"] = null
        rmours["specific_provenance"] = rm["specific_provenance"]
        rmours["alreadySent"] = true
        rmours["pre_existing"] = true
        relation_mentions = [...relation_mentions, rmours]
        // annotation
        rmours['annotation_id'] = rm.location.anotacion || null
        rmours['valid_annotation'] = rm.location.is_valid
    }
    return {
        "entments": entity_mentions,
        "relments": relation_mentions
    }

}


// --------------------------------------------------------
//   G E T S   --------------------------------------------
// --------------------------------------------------------

// async function POST(url, data){
//     let tok = localStorage.getItem("vue-token")
//     let config = { headers: { Authorization: 'Bearer '+tok} };
//     return axios.post(url, data, config)
// }

const GET = async (url) => {
    const token = localStorage.getItem('angelus-token') ?? ''
    return axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

const GETIMAGE = async (url) => {
    const token = localStorage.getItem('angelus-token') ?? ''
    return axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'blob',
    })
}


export async function get_doc_metadata(docuri) {
    // var docuri = "<" + base_doc_uri + shortdocid +">"
    var url = doc_api_base + "metadata?docid=" + docuri
    var md = {}
    var GP = GET(url)
    await GP.then(resp => { md = resp.data })
    return md
}

export const get_list_of_documents = async () => {
    const url = `${doc_api_base}documents?multipart=true&per_page=1000&order=desc&sort=date`
    const { data } = await GET(url)

    return data["results"]
}




export async function get_info_on_entities(entitylist) {
    var whole_response = {}
    var url = api_base + api_get_severalentities_info
    //await axios.post(url, entitylist)
    await POST(url,entitylist)
        .then(r => {
            var entity_info
            for (var xn in r.data) {
                var x = r.data[xn]
                entity_info = convert_info_of_entities(x["data_properties"],
                    x["object_properties"],
                    x["inverse_properties"])
                whole_response[x["uri"]] = entity_info
            }
        })
        .catch(err => console.log(err))

    return whole_response

}

export async function get_mentions_in_document(doc_ic) {
    var url = api_base + api_mentions_by_doc + doc_ic
    var whole_response = {}
    await GET(url)
        .then(resp => {
            console.log('///whole', resp);
            whole_response = resp.data;
        })
        .catch(err => console.log(err))

    return convertmentions(whole_response)

}

export async function get_polygons_in_document(doc_ic) {
    var url = api_base + api_mentions_by_doc + doc_ic;
    let polygons = [];
    try {
        const { data } = await GET(url);
        if (data) {
            const polygonsByEntity = data.mentioned_entities.map(e => {
                return e.locations
                .filter(location => location.polygon)
                .map(location => location.polygon);
            });
            polygonsByEntity.forEach(element => {
                polygons = [polygons, ...element];
            });
        }
    } catch(error) {
        console.log(error);
        return [];
    }

    return polygons;
}

export async function get_narrower(broader_uri){
    var url = api_base + api_get_narrower + broader_uri    
    var narrower = {}
    await GET(url)
        .then(resp => {narrower = convert_catalogs(resp.data["catalogs"])
                       console.log("Narrowers downloaded ")
                       console.log(narrower)})
        .catch(err => console.log(err) )

    return narrower
}

export async function get_list_of_classes() {

    var url = api_base + api_static_schemas
    var classes_data = []
    var catalogs = {}
    var whole_response = {}
    var properties = {}

    await GET(url)
        .then(resp => whole_response = resp.data)
        .catch(err => console.log(err))

    classes_data = convert_list_of_classes(whole_response["classes"])
    catalogs = convert_catalogs(whole_response["catalogs"])
    properties = convert_info_of_properties(whole_response["properties"])


    return {
        "classes_data": classes_data,
        "catalogue_data": catalogs,
        "property_data": properties
    }
}

export async function get_entities_of_all_classes() {
    var url = api_base + api_get_entities_all_classes
    var class_enitity_list = [];
    await GET(url)
        .then(resp => {
            class_enitity_list = resp.data["classes_and_entities"]
        })
        .catch(err => console.log(err))
    return class_enitity_list
}

export async function get_entities_of_class(class_id) {
    var url = api_base + api_get_entities_of_class + class_id
    var enitity_list = [];
    await GET(url)
        .then(resp => enitity_list = convert_list_of_entities(resp.data["entities_with_labels"]))
        .catch(err => console.log(err))
    return enitity_list;
}

export async function get_info_on_entity(entity_id) {
    var url = api_base + api_get_entityinfo + entity_id
    var entity_info = {}
    await GET(url)
        .then(resp => entity_info = convert_info_of_entities(resp.data["data_properties"],
            resp.data["object_properties"],
            resp.data["inverse_properties"]))
        .catch(err => console.log(err))
    return entity_info;
}

export async function get_property_info() {
    var url = api_base + api_property_info
    var property_info = {}
    await GET(url)
        .then(resp => property_info = convert_info_of_properties(resp.data["properties"], resp.data["object_properties"]))
        .catch(err => console.log(err))
    return property_info
}

export async function get_document(url) {
    const image = await GETIMAGE(url).then(resp => resp.data);
    return image
}



// --------------------------------------------------------
//   P O S T S --------------------------------------------
// --------------------------------------------------------


async function POST(url, payload) {
    let tok = localStorage.getItem("angelus-token")
    let config = { headers: { Authorization: 'Bearer ' + tok } };
    return axios.post(url, payload, config)
}

export async function add_existing_entity_mention(entity_id, doc_id) {
    //var rp = ""
    var url = api_base + api_mention_existing_entity
    var payload = {
        "entity_id": entity_id,
        "location": {
            "soporte_documental": doc_id,
        }
    }
    var prom = POST(url, payload)
    //            .then(r => rp = r)
    return prom
}

export async function add_new_entity_mention(class_uri,
    data_properties, object_properties,
    doc_id) {
    var rp = ""
    var url = api_base + api_mention_new_entity
    var payload = {
        "location": { "soporte_documental": doc_id },
        "entity_definition": {
            "class_uri": class_uri,
            "data_properties": data_properties,
            "object_properties": object_properties
        }
    }
    //await axios.post(url, payload).then(r => rp = r)
    await POST(url, payload).then(r => rp = r)
    console.log(rp)
    return rp
}

export async function add_relation_mention(payload) {
    var url = api_base + api_mention_relation
    var rp = ""
    //await axios.post(url, payload).then(r => rp = r)
    await POST(url, payload).then(r => rp = r)
    console.log("response " + rp)
    return rp
}



// --------------------------------------------------------
//   P A T C H S ------------------------------------------
// --------------------------------------------------------


async function PATCH(url, payload) {
    let tok = localStorage.getItem("angelus-token")
    let config = { headers: { Authorization: 'Bearer ' + tok } };
    return axios.patch(url, payload, config)
}


export async function add_data_properties_to_entity(entity_id,
    data_properties, object_properties,
    doc_id) {
    //var rp = ""
    var url = api_base + api_patch_entity
    var payload = {
        "location": { "soporte_documental": doc_id },
        "entity_id": entity_id,
        "data_properties": data_properties,
        "object_properies": object_properties
    }
    console.log('ADDDDD', payload)
    return PATCH(url, payload)
    // .then(r => rp = r)        
    //return rp

}

// --------------------------------------------------------
//   DELETES   --------------------------------------------
// --------------------------------------------------------


const DEL = async (url) => {
    const token = localStorage.getItem('angelus-token') ?? ''
    return axios.delete(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export async function del_invalid_annotation(annotation_id) {
    const url = api_base + api_del_annotation + annotation_id
    const prom = DEL(url)
    return prom
}
