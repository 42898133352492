var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},[_c('div',{staticClass:"text-h5 font-weight-bold text-center"},[_vm._v("Documentos disponibles")]),_c('v-card',{staticClass:"ma-xs-5 mx-md-16 my-md-10"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"documents-table",attrs:{"dense":"","fixed-header":"","headers":_vm.headers,"items":_vm.documents,"items-per-page":50,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando...","header-props":{
        sortByText: 'Ordenar por',
      },"footer-props":{
        showFirstLastPage: true,
        itemsPerPageText: 'Documentos por página',
        itemsPerPageAllText: 'Todos',
        itemsPerPageOptions: [10, 15, 25, 50, 100, -1]
      },"no-data-text":"Sin información","no-results-text":"Sin coincidencias"},on:{"click:row":_vm.goToAnnotator}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }