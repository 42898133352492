import Vue from 'vue'
import { get_list_of_documents, get_doc_metadata } from '@/api/ontologias'

const getDocuments = async function () {
  try {
    this.loading = true
    this.documents = await get_list_of_documents()
  } catch(error) {
    Vue.$log.error(error)
    this.documents = [];
  } finally {
    this.loading = false
  }
}

const getMetadata = async function (uri) {
  const metadata = await get_doc_metadata(uri)

  const baseUrl = 'https://ontologias.pruebascnb.org/v1.0/'
  const formattedMetadata = {
    title: metadata[`<${baseUrl}tieneTitulo>`],
    foliaciones: metadata[`<${baseUrl}tieneFoliacion>`],
    padre: metadata[`<${baseUrl}perteneceA>`],
    tipoDocumento: metadata['Content-Type'] ?? 'application/pdf',
    copartes: metadata[`<${baseUrl}tieneCoParte>`] ?? [],
    paginas: metadata['<http://purl.org/dc/terms/hasPart>'] ?? [],
  }

  return formattedMetadata
}

const goToAnnotator = async function (document) {
  try {
    const metadata = await getMetadata(document.uri)
    let docid = null
    
    if (metadata.paginas.length) {
      const split = metadata.paginas[0].split('/')
      docid = split.slice(-2).join('/').slice(0, -1)
    } else {
      docid = document.id
    }
    
    // this.$router.push({ name: 'Anotador', params: { docid } })
    const route = this.$router.resolve({ name: 'Anotador', params: { docid } });
    window.open(route.href, '_blank');
  } catch(error) {
    Vue.$log.error(error)
  }
}

export default {
  name: 'DocumentList',
  data () {
    return {
      search: '',
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          value: 'title',
        },
        { 
          text: 'Acciones',
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ],
      documents: [],
      loading: false,
    }
  },
  methods: {
    getDocuments,
    goToAnnotator,
  },
  mounted () {
    this.getDocuments()
  },
}