<template>
  <v-app>
    <v-app-bar
      app
      color="#FAFAFA"
    >
      <div class="d-flex align-center justify-start">
        <v-img
          alt="Conacyt Logo"
          class="shrink"
          contain
          src="conacyt.png"
          transition="scale-transition"
          width="60"
        />
        <v-img
          alt="CNB Logo"
          class="shrink"
          contain
          src="logo.png"
          transition="scale-transition"
          width="150"
        />
        <div class="d-none d-md-flex text-h6 ml-2 grey--text">Anotador Angelus</div>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-on:click="logout"
        color="primary"
        text
      >
        <span class="mr-1">Cerrar sesión</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
  <!--<div id="nav">
      <router-link to="/home">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/">Anotador</router-link>
    </div>-->
</template>

<script>
  export default {
    name: 'App',
    methods: {
      logout() {
        this.$keycloak.logout()
      }
    }
  }
</script>

<style>
  .v-expansion-panel-content__wrap {
    padding: 0 12px 16px !important;
  }

  .container {
    padding: 8px !important;
  }

  .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10,
  .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5,
  .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
  .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9,
  .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4,
  .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto,
  .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8,
  .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3,
  .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12,
  .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7,
  .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2,
  .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9,
  .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding: 8px !important;
  }

  .row {
    margin: -8px !important;
  }
</style>
