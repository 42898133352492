import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#009688', // #49ADA9
        secondary: '#607d8b',
        accent: '#00bcd4',
        error: '#f44336',
        warning: '#f5b907', // '#ffc107'
        info: '#2196f3',
        success: '#4caf50',
        light: '#f4f4f4',
        white: '#ffffff',
      },
    },
  },
});
