import Vue from 'vue'
import Keycloak from 'keycloak-js'
import VueLogger from 'vuejs-logger'
import App from './App.vue'
import loggerOptions from './logger'
import router from './router'
import vuetify from './plugins/vuetify'

import { store } from './storedef'

import jwtDecode from 'jwt-decode'


Vue.config.productionTip = false


/** LOGGER */

Vue.use(VueLogger, loggerOptions)


/** AUTHENTICATION */

const initOptions = {
  url: process.env.VUE_APP_AUTHSERVER,
  realm: process.env.VUE_APP_REALM,
  clientId: process.env.VUE_APP_CLIENTID,
  onLoad: 'login-required'
}

let keycloak = Keycloak(initOptions)
Vue.prototype.$keycloak = keycloak

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload()
  } else {
    Vue.$log.info("Authenticated")

    localStorage.setItem("angelus-token", keycloak.token)
    const decoded = jwtDecode(keycloak.token)
    localStorage.setItem("angelus-roles", decoded.realm_access?.roles || [])

    const isWritterPub = decoded.resource_access?.grafo_publico?.roles.indexOf('escritura') > -1;
    const isWritterPriv = decoded.resource_access?.grafo_privado?.roles.indexOf('escritura') > -1;
    const isWritter = isWritterPub || isWritterPriv;

    localStorage.setItem("angelus-writter", isWritter);

    new Vue({
      el: '#app',
      router,
      store,
      vuetify,
      render: h => h(App)
    })
  }


//Token Refresh
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        Vue.$log.info('Token refreshed ' + refreshed);
        localStorage.setItem("angelus-token", keycloak.token)

        const decoded = jwtDecode(keycloak.token)
        localStorage.setItem("angelus-roles", decoded.realm_access?.roles || [])

        const isWritterPub = decoded.resource_access?.grafo_publico?.roles.indexOf('escritura') > -1;
        const isWritterPriv = decoded.resource_access?.grafo_privado?.roles.indexOf('escritura') > -1;
        const isWritter = isWritterPub || isWritterPriv;

        localStorage.setItem("angelus-writter", isWritter);
      } else {
        Vue.$log.warn('Token not refreshed, valid for '
          + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
      }
    }).catch(() => {
      Vue.$log.error('Failed to refresh token')
      keycloak.logout()
      window.location.reload()
    })
  }, 60000)

}).catch(() => {
  Vue.$log.error('Authenticated Failed')
});
